import React, { Component } from 'react';
import { Grid, Header } from 'semantic-ui-react';

export default class Welcome extends Component {
    render() {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <img
                            className='scopeLogo'
                            src='images/SCope_Logo.png'
                            width='320'
                        />
                        <br />
                        <br />
                        SCope is a fast, user-friendly visualization tool for
                        large-scale scRNA-seq datasets, published{' '}
                        <a
                            href='https://doi.org/10.1016/j.cell.2018.05.057'
                            target='_blank'
                            rel='noopener noreferrer'>
                            here
                        </a>
                        .
                        <br />
                        Datasets can be uploaded in user sessions using the left
                        sidebar. Files should be in the{' '}
                        <a
                            href='http://loompy.org/'
                            target='_blank'
                            rel='noopener noreferrer'>
                            loom file format
                        </a>{' '}
                        and will appear under the &quot;User Uploaded&quot;
                        category.
                        <br />
                        You can obtain a new user session using the "Get new
                        session" button in the top right.
                        <br />
                        <br />
                        SCope compatible loom files can be generated using the
                        following packages:
                        <ul>
                            <li>
                                R:{' '}
                                <a
                                    href='https://github.com/aertslab/SCopeLoomR'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    SCopeLoomR
                                </a>
                            </li>
                            <li>
                                Python:{' '}
                                <a
                                    href='https://github.com/aertslab/LoomXpy'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    LoomXpy
                                </a>
                                {' or '}
                                <a
                                    href='https://github.com/aertslab/pySCENIC'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    pySCENIC
                                </a>
                            </li>
                        </ul>
                        <br />
                        Currently on{' '}
                        <a
                            href='http://scope.aertslab.org'
                            target='_blank'
                            rel='noopener noreferrer'>
                            scope.aertslab.org
                        </a>{' '}
                        the following sessions are publically available:

                        <ul>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/scATAC-seq_Benchmark/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    scATAC-seq_Benchmark
                                </a>
                                {' - Data from: '}
                                <b>
                                    Systematic benchmarking of single-cell ATAC-sequencing protocols
                                </b>
                                , De Rop <i>et al.</i>, 2023 (
                                <a
                                    href='https://doi.org/10.1038/s41587-023-01881-x'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature Biotechnology
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Bravo_et_al_Liver/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Bravo_et_al_Liver
                                </a>
                                {' - Data from: '}
                                <b>
                                    Enhancer grammar of liver cell types and hepatocyte zonation states
                                </b>
                                , Bravo González-Blas <i>et al.</i>, 2023 (
                                <a
                                    href='https://doi.org/10.1101/2022.12.08.519575'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    bioRxiv
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/FlyCellAtlas/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    FlyCellAtlas
                                </a>
                                {' - Data from: '}
                                <b>
                                    Fly Cell Atlas: A single-nucleus transcriptomic atlas of the adult fruit fly
                                </b>
                                , Li and Janssens <i>et al.</i>, 2022 (
                                <a
                                    href='https://doi.org/10.1126/science.abk2432'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Science
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Fly_Brain/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Fly_Brain
                                </a>
                                {' - Data from: '}
                                <b>
                                    Decoding gene regulation in the fly brain
                                </b>
                                , Janssens <i>et al.</i>, 2022 (
                                <a
                                    href='https://doi.org/10.1038/s41586-021-04262-z'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Drosophila_Leg/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Drosophila_Leg
                                </a>
                                {' - Data from: '}
                                <b>
                                    Origins of proprioceptor feature selectivity and topographic maps in the Drosophila leg
                                </b>
                                , Mamiya <i>et al.</i>, 2023 (
                                <a
                                    href='https://doi.org/10.1101/2022.08.08.503192'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    bioRxiv
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Davie_et_al_Cell_2018/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Davie_et_al_Cell_2018
                                </a>
                                {' - Data from: '}
                                <b>
                                    A single-cell transcriptome atlas of the
                                    ageing Drosophila brain
                                </b>
                                , Davie, Jannssens and Koldere <i>et al.</i>,
                                2018 (
                                <a
                                    href='https://doi.org/10.1016/j.cell.2018.05.057'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Cell
                                </a>
                                )
                                <ul>
                                    Files previously available under the
                                    "Publicly Available" section can be found
                                    here.
                                </ul>
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/scenic-v2/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    scenic-v2
                                </a>
                                {' - Data from: '}
                                <b>
                                    SCENIC+: single-cell multiomic inference of enhancers and gene regulatory networks
                                </b>
                                , Bravo González-Blas and De Winter <i>et al.</i>, 2022 (
                                <a
                                    href='https://doi.org/10.1101/2022.08.19.504505'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    bioRxiv
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Octopus_Brain/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Octopus_Brain
                                </a>
                                {' - Data from: '}
                                <b>
                                    Cell type diversity in a developing octopus brain
                                </b>
                                , Styfhals <i>et al.</i>, 2022 (
                                <a
                                    href='https://doi.org/10.1101/2022.01.24.477459'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    bioRxiv
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/HyDrop/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    HyDrop
                                </a>
                                {' - Data from: '}
                                <b>
                                    HyDrop: droplet-based scATAC-seq and scRNA-seq using dissolvable hydrogel beads
                                </b>
                                , De Rop <i>et al.</i>, 2021 (
                                <a
                                    href='https://doi.org/10.7554/eLife.73971'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    eLife
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Bravo_et_al_EyeAntennalDisc/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Bravo_et_al_EyeAntennalDisc
                                </a>
                                {' - Data from: '}
                                <b>
                                    Identification of genomic enhancers through spatial integration of single-cell transcriptomics and epigenomics
                                </b>
                                , Bravo <i>et al.</i>, 2020 (
                                <a
                                    href='https://doi.org/10.15252/msb.20209438'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Molecular Systems Biology
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Bernard_Thienpont/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Bernard_Thienpont
                                </a>
                                {' - Data from: '}
                                <b>
                                    Phenotype molding of stromal cells in the
                                    lung tumor microenvironment
                                </b>
                                , Lambrechts <i>et al.</i>, 2018 (
                                <a
                                    href='https://doi.org/10.1038/s41591-018-0096-5'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature Medicine
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Wouters_Human_Melanoma/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Wouters_Human_Melanoma
                                </a>
                                {' - Data from: '}
                                <b>
                                    Robust gene expression programs underlie
                                    recurrent cell states and phenotype
                                    switching in melanoma
                                </b>
                                , Wouters <i>et al.</i>, 2020 (
                                <a
                                    href='https://doi.org/10.1038/s41556-020-0547-3'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature Cell Biology
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/Protocol_Cases/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Protocol_Cases
                                </a>
                                {' - Data from: '}
                                <b>
                                    A scalable SCENIC workflow for single-cell
                                    gene regulatory network analysis
                                </b>
                                , Van de Sande <i>et al.</i>, 2020 (
                                <a
                                    href='https://doi.org/10.1038/s41596-020-0336-2'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature Protocols
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/PancreasAtlas/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    PancreasAtlas
                                </a>
                                {' - Data from: '}
                                <b>
                                    Revealing the Key Regulators of Cell
                                    Identity in the Human Adult Pancreas
                                </b>
                                , Vanheer <i>et al.</i>, 2020 (
                                <a
                                    href='https://doi.org/10.1101/2020.09.23.310094'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    bioRxiv
                                </a>
                                )
                            </li>
                            <li>
                                <a
                                    href='https://scope.aertslab.org/#/HumanPluripotencyPRC2/*/welcome'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    HumanPluripotencyPRC2
                                </a>
                                {' - Data from: '}
                                <b>
                                    Integrated multi-omics reveal polycomb repressive complex 2 restricts human trophoblast induction
                                </b>
                                , Zijlmans <i>et al.</i>, 2022 (
                                <a
                                    href='https://doi.org/10.1038/s41556-022-00932-w'
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    Nature Cell Biology
                                </a>
                                )
                            </li>
                        </ul>
                        <br />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
